.filtering-sidebar canvas {
    margin: 0.2em 0;
    cursor: pointer !important;
}

.filter-preview {
    position: relative;
    width: 96%;
    margin-bottom: 5%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    background-color: rgb(195, 195, 205)
}

.filter-preview:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.filter-container {
    padding-bottom: 10px;
}

.filter-preview h4 {
    font-size: 17px;
    font-weight: bold;
}
.filter-preview canvas {
    border-radius: 5px;
}
