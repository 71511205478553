.brandupload-form {
  padding: 20px;
}

.brandupload-form h2 {
  font-size: 18px;
  font-weight: bold;
}

.brandupload-form div {
  display: flex;
  align-items: flex-start !important;
  width: 100%;
}

.brandupload-form .ant-form-item-children {
  width: 100%;
}

.brandupload-form p {
  margin: 0 auto !important;
  width: 90%;
  font-size: 13px !important;
}

.brandupload-form .ant-form {
  width: 100%;
}

.brandupload-form .ant-upload-list-item-info>span {
  width: 100%;
}

.branding-list h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.branding-item {
  display: block;
  cursor: pointer;
  width: 96%;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  background-color: rgb(195, 195, 205)
}

.branding-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.branding-item-container {
  padding: 10px 5px;
}

.branding-item .branding-item-img {
  width: 90%;
  border-radius: 5px;
}

.branding-item-img.active {
  border: 2px solid rgb(28, 28, 33);
}

.branding-item h4 {
  font-size: 17px;
  font-weight: bold;
}

.branding-item.selected {
  border: 2px solid rgb(24, 144, 255);
}