.classifications {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.session-photo-section {
  height: 400px;
  width: 100%;
}

.all-photos {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.before,
.after {
  position: relative;
  z-index: 0;
  color: #eee;
  cursor: pointer;
}

.before .back-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.after .forward-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.session-photo-section .before,
.session-photo-section .after {
  height: 300px;
  width: 50%;
  background: #999;
  overflow-x: hidden;
  display: flex;
}

.session-photo-section .current-photo {
  height: 400px;
  width: 530px;
  background: #111;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 1;
}
.before-image,
.after-image {
  height: 300px;
  width: 400px;
}

.classifications-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  margin-top: 2em;
}

.classification {
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.classification h4 {
  margin-top: 1em;
  font-weight: 700;
}

.card.classification {
  height: 150px;
  width: 150px;
}

.classification .classification-thumbnail img {
  height: auto;
  width: 150px;
}

.current-photo-overlay {
  height: 100%;
  width: 100%;
  position: relative;
  color: #eee;
}

.current-photo-footer {
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.photos-remaining {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-weight: 800;
  color: #eee;
}

.classification-type {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 800;
  color: #eee;
}

.no-photos {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-weight: 800;
  color: #eee;
}

.before-overlay,
.after-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.8;
}

.hidden {
  display: none;
}
.modal-main-container {
  width: 100%;
  height: 100%;
}
.modal-current-photo {
  height: 400px;
  width: 530px;
  background: #111;
}
.modal-image-options {
  display: flex;
  flex-direction: row;
}

.session-submenu-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.session-submenu {
  width: 100%;
  height: 50px;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 1px solid rgb(200, 200, 200);
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-submenu-item {
  text-decoration: underline;
  cursor: pointer;
  color: #111;
  font-size: 13px;
  font-weight: 700;
  margin-right: 40px;
  line-height: 30px;
}

.session-submenu ul {
  height: 30px;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  list-style-type: none;
  display: flex;
}
.requirements-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
