.photos-annotation-list-item {
  display: flex;
  flex-direction: column;
  .photos-annotation-item-view {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    border: 2px solid #916794;
    border-radius: 0.5vw;
    color: rgba(0, 0, 0, 0.85);
    padding: 0.5vw;
    cursor: pointer;

    .photos-annotation-item-title {
      width: 100%;
      position: relative;
      .svg-inline--fa.fa-edit {
        position: absolute;
        right: 0;
        width: 1.25em;
        height: 1.25em;
      }

      .svg-inline--fa.fa-trash {
        position: absolute;
        right: 2em;
        width: 1em;
        height: 1.25em;
      }
    }

    .photos-annotation-item-image {
      width: 100%;
      margin-top: 0.75vw;
      margin-bottom: 0.75vw;

      img {
        width: 95%;
        max-height: 150px;
        min-height: 150px;
        margin-left: 2.5%;
      }
    }

    p {
      font-weight: 700;
      text-align: center;
      margin-bottom: 0.5vw;
    }

    &.active {
      border: 3px solid #1a90ff;
    }
  }

  .new-annotation-window {
    padding: 10px;

    .ant-form-item {
      margin-bottom: 7px;
    }

    .ant-upload-text {
      font-size: 12.5px;
    }

    .new-annotation-photo-container {
      svg {
        width: 0.35em;
      }
    }
  }
}
