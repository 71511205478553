.panoview-annnotation {
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex;

  .panoview-sidebar {
    height: 100%;
    background: #fff;
    display: flex;

    .panoview-sidebar-outer {
      background: #fff;
      width: 0;
      border-top: 1px solid #eee;
      height: 100%;
      transition: 0.25s ease-in-out all;
      position: relative;

      &.active {
        border-right: solid rgb(187, 187, 187) 1px;
        width: 350px;
        overflow-y: scroll;
      }

      .panoview-sidebar-toolbar {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 1vw;

        button {
          width: 80%;
          height: 2vw;
        }
      }

      .panoview-annotation-form {
        padding: 25px;

        .ant-form-item-label {
          line-height: 20px;

          label {
            font-size: 12px;
          }
        }

        .ant-form-item .ant-form-item {
          margin-bottom: 5px;
        }

        p {
          margin: 0 auto !important;
          width: 90%;
          font-size: 12px !important;
        }

        .bottom {
          display: flex;
          justify-content: space-around;
        }
      }

      .panoview-annotation-list-item {
        .panoview-annotation-item-view {
          width: 80%;
          margin-left: 10%;
          margin-top: 1vw;
          margin-bottom: 1vw;
          border: 2px solid #916794;
          border-radius: 0.5vw;
          color: rgba(0, 0, 0, 0.85);
          padding: 0.5vw;
          cursor: pointer;

          .panoview-annotation-item-title {
            position: relative;
            .svg-inline--fa.fa-edit {
              position: absolute;
              right: 0;
              width: 1.25em;
              height: 1.25em;
            }

            .svg-inline--fa.fa-trash {
              position: absolute;
              right: 2em;
              width: 1em;
              height: 1.25em;
            }
          }

          .panoview-annotation-item-image {
            width: 100%;
            margin-top: 0.75vw;
            margin-bottom: 0.75vw;

            img {
              width: 95%;
              max-height: 150px;
              min-height: 150px;
              margin-left: 2.5%;
            }
          }

          p {
            font-weight: 700;
            text-align: center;
            margin-bottom: 0.5vw;
          }

          &.active {
            border: 3px solid #1a90ff;
          }
        }
      }
    }
  }

  .panoview-main-view {
    flex-grow: 1;
    background: #1c1c21;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .view-switch-button {
      position: absolute;
      right: 2.5%;
      top: 2.5%;
    }

    .view-back-button {
      position: absolute;
      left: 2.5%;
      top: 2.5%;
    }

    .view-center-button {
      position: absolute;
      top: 92.5%;
    }

    .panoview-360view-wrapper {
      height: 70%;
      width: 60%;
      // overflow-y: auto;
      position: relative;
      align-items: center;

      #panoview-360view {
        position: relative;
        width: 100%;
        height: 100%;
        outline: none;
        overflow: hidden;

        canvas {
          width: 100%;
          height: 100%;
        }

        .annotation-frame-done {
          position: absolute;
          right: 5%;
          top: 5%;
        }

        .panoview-annotation-pos {
          cursor: pointer;
          position: absolute;

          .panoview-annotation-pos-icon {
            width: 12.5px;
            height: 12.5px;
            background-color: rgb(0, 223, 252);
            border-radius: 50%;
          }

          &.active {
            .panoview-annotation-pos-icon {
              border: 2px solid white;
            }
          }
        }
      }

      .panoview-info {
        display: flex;

        .panoview-edit-message {
          flex: 1;
          text-align: center;
          padding: 5px 10px;
          color: #fff;
          line-height: 20px;
          height: 30px;
        }
      }
    }

    .panoview-loading-container {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
