header.jombler-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #DCDCDC;
    height: 80px;
}

.logo {
    height: 100%;
    display: flex;
    align-items: center;
}
.logo img {
    height: 30px;
    width: auto;
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
}

.links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.links ul li {
    padding: 0 0.5em;
}

.links ul a {
    text-decoration: none;
    color: #111;
    font-size: 13px;
    font-weight: 700;
}

.ant-dropdown-trigger {
    cursor: pointer;
}

.jombler-header .ant-dropdown-menu {
    padding: 1em;
}