.password-reset-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4em);
  margin: auto;
  background: #fff;
}

.password-reset-page input {
  width: 100%;
}

.password-reset-page h1 {
  font-weight: 700;
  margin-bottom: 1vw;
  margin-top: 2vw;
}

.password-reset-form {
  width: 100%;
  padding: 0;
  margin-top: 1vw;
  margin-bottom: 2vw;
}

.password-reset-form-bottom .ant-form-item-children {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row.password-reset-row {
  width: 500px;
  min-width: 500px;
  height: 60vh;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.password-reset-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.password-reset-logo img {
  height: 40px;
  width: auto;
  position: relative;
  bottom: 1em;
}
