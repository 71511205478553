#dashboard {
    width: 100%;
    height: 100%;
}

.dashboard-card-wrapper {
    position: relative;
    margin: .5vw;
}
.dashboard-card-wrapper button {
    display: none;
    position: absolute;
    right: 5px;
    top: 5px;
}

.dashboard-card-wrapper:hover button {
    display: block;
}

.dashboard-card-wrapper .card {
    margin: 0;
}

.vin-card a {
    height: 100%;
    width: 100%;
}


.vin-card.new-session-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vin-card.new-session-card .card-title {
    padding-top: 1em;
}

.vin-card.new-session-card svg {
    color: #111;
}

.vin-card .body-section {
    padding: 12px;
}

.vin-card .body-section h4,
.vin-card .body-section h5 {
    margin: 0;
}

.card-photo-section {
    width: 300px;
    height: calc(300px * 3/4);
    border: 1px solid #eee;
}

.card-photo-section img {
    height: 100%;
    width: 100%;
}

.search-header {
    background: #fff;
    padding: 1em 50px;
}

.search-header h4 {
    font-weight: 700;
    font-size: 13px;
}

#search.search-header .ant-select-auto-complete.ant-select-lg .ant-input {
    background: #f5f5f5;
    border: none;
    padding-left: 0;
    height: 60px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: 0;
    box-shadow: none;
}

#search.search-header .ant-select-auto-complete.ant-select-lg .ant-input:focus {
    border: none !important;
}

#search.search-header .ant-input-group-addon {
    background: #f5f5f5;
    border: none;
    padding: 0 1em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 21px;
    cursor: default;
}

#search.search-header .stats {
    padding-top: 1em;
    display: flex;
    flex-direction: row;
}

#search.search-header .stats h5 {
    margin-right: 12px;
}

.vehicle-results {
    display: flex;
    flex-direction: row;
    padding: 2em 0;
    flex-wrap: wrap;
}

.search-vins-footer {
  display: flex;
  justify-content: space-between;
}

.search-vins-footer .ant-pagination {
  padding: .5em 0 0 0;
}

.search-vins-footer .ant-pagination .ant-pagination-total-text {
  font-weight: 700;
  color: #949494;
  font-size: .83em;
}