#finalize .search-vins {
  margin-bottom: 2em;
}

#finalize .vin-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#finalize .vin-card .body-section {
  margin-top: 5px;
  padding: 5px;
  text-align: center;
}

#finalize > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.vin-card .body-section h4,
.vin-card .body-section h5 {
  margin: 0;
}

#finalize .vin-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#finalize .card.vin-card {
  width: 350px;
  height: auto;
}

#finalize .card.vin-card .photo-item {
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.view-label {
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}

.spinview-preview {
  width: 50%;
  min-height: 65%;
  margin-top: 1.5%;
  margin-bottom: 1.5%;

  iframe {
    outline: none;
    border: none;
  }
}

.panoview-preview {
  width: 50%;
  min-height: 65%;
  margin-top: 1.5%;
  margin-bottom: 1.5%;

  iframe {
    outline: none;
    border: none;
  }
}
