.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 4em);
    margin: auto;
    background: #fff;
}

.login-page input {
    width: 100%;
}

.login-page h1 {
    font-weight: 700;
    margin-bottom: 0;
}

.login-form {
    width: 100%;
    padding: 0;
}

.login-form-bottom .ant-form-item-children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.row.login-row {
    width: 500px;
    min-width: 500px;
    height: 70vh;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
}

.login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login-logo img {
    height: 40px;
    width: auto;
    position: relative;
    bottom: 1em;
}


/*.welcome {*/
    /*background: url(https://www.ovad.com/uploads/1/1/9/0/119051462/stage-photo-dome1-1300px-846px_orig.jpg);*/
    /*height: 100%;*/
    /*background-size: auto;*/
    /*background-position: -126px 0;*/
    /*width: 100%;*/
/*}*/