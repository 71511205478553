.session-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .spinview-overlay {
        position: absolute;
        height: 16vw;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        margin-top: 50px;
        z-index: -1;
    }

    .session-view-selection {
        position: absolute;
        height: 50px;
        display: flex;
        align-items: center;
        margin-left: 1.25%;
    }

    .container-full-width {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .button-row {
        margin-top: 1vw;
    }
}

.preview-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .spinview-preview {
        width: 45vw;
        min-height: 27.5vw;
        margin-top: 1.5%;
        margin-bottom: 1.5%;

        iframe {
            outline: none;
            border: none;
        }
    }

    .panoview-preview {
        width: 45vw;
        min-height: 27.5vw;
        margin-top: 1.5%;
        margin-bottom: 1.5%;

        iframe {
            outline: none;
            border: none;
        }
    }
}
