.content-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.metric-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .metric-content-section {
        width: 95%;
        margin-top: 2em;
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 3px;
        background-color: #c3c3cd4d;
        padding: 1.5em 1.5em 2em 1.5em;

        .metric-section-title {
            font-size: 1.75em;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 1em;
        }

        .metric-card-container {
            display: flex;
            justify-content: space-around;

            .metric-card-body {
                position: relative;
                width: 23%;
                height: fit-content;
                background-color: rgb(255, 255, 255);
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                transition: 0.3s;
                border-radius: 5px;

                .metric-icon-container {
                    position: absolute;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                    transition: 0.3s;
                    left: 5%;
                    top: 1em;
                    width: 5.5em;
                    height: 5.5em;
                    border-radius: 5px;
                    background-size: 80% 80%;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .metric-info-container {
                    margin-top: 1.5em;

                    .metric-title {
                        width: 70%;
                        margin-left: 30%;
                        position: relative;
                        right: 0px;
                        text-align: right;
                        padding-right: 1.25em;
                        text-transform: capitalize;
                        font-size: 1.15em;
                        font-weight: bold;
                        height: 2.5em;
                    }

                    .metric-value {
                        text-align: right;
                        padding-right: 0.75em;
                        font-size: 2.75em;
                        font-weight: 700;
                        margin-bottom: 0;
                    }
                }

                .time-selector {
                    height: 3.5em;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h2 {
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 1.1em;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .recent-session-container {
        margin-top: 3em;
        width: 95%;
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 3px;
        background-color: #c3c3cd4d;
        padding: 1.5em 1.5em 0em 1.5em;

        .recent-session-title {
            font-size: 1.75em;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0.5em;
        }

        .recent-session-vehicle {
            font-size: 1.25em;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.85);

            .recent-session-vehicle-subtitle {
                font-weight: bold;
            }

            .recent-session-vehicle-value {
                margin-right: 0.5em;
            }
        }

        .photo-container {
            width: 75vw;
            display: flex;
            flex-direction: row;
            overflow-x: auto;

            .photo-item {
                width: 12vw;
                min-width: 12vw;
                height: 12vw;
                margin-left: 1vw;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .container-360 {
            width: 75vw;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .spinview-preview {
                width: 25vw;
                min-height: 15vw;
                margin-top: 1.5%;
                margin-bottom: 1.5%;
                box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;

                iframe {
                    outline: none;
                    border: none;
                }
            }

            .panoview-preview {
                width: 25vw;
                min-height: 15vw;
                margin-top: 1.5%;
                margin-bottom: 1.5%;
                box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);

                iframe {
                    outline: none;
                    border: none;
                }
            }
        }
    }
}

.metric-sidebar {
    width: 20%;
    background: #0D181C;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .sidebar-title-container {
        margin-top: 1.5em;
        margin-bottom: 1em;
        width: 100%;
        height: 4em;

        .title {
            font-size: 2em;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            color:white;
        }
    }

    .side-card-container-list {
        flex: 1;

        .site-card-container {
            cursor: pointer;
            position: relative;
            margin: auto;
            margin-bottom: 2em;
            height: 10em;
            width: 80%;
            box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            border-radius: 2.5px;
            background-color: #f4f8fa;

            &.selected {
                border: 3px solid rgb(0, 128, 248);
            }
        }

        .site-card-container:hover {
            background-color: #a9cddb;
        }

        .site-card-container h4 {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 1.35em;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.clear-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 15px;
    cursor: pointer;
}
