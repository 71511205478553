
.classifications {
    width: 70%;
    margin: 0 auto;
}
.add-classification-row {
    display: flex;
    justify-content: flex-end;
    padding: 1em 0;
}
.ant-table-wrapper {
    width: 100%;
    margin: 0 auto;
}

.ant-table-wrapper thead {
    font-weight: 700;
    color: #111;
}

.ant-table-wrapper tbody tr {
    background: #fff;
}