.annotation-pos {
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.annotation-window {
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 20;
  padding: 10px;
}

.annotation-window::before {
  position: absolute;
  top: 17px;
  right: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(255, 255, 255, 1);
  content: '';
}

.annotation-pos-icon.circle-icon {
  width: 15px;
  height: 15px;
  background-color: rgb(0, 223, 252);
  border: 2px solid rgb(64, 255, 255);
  border-radius: 50%;
}

.annotation-pos.active .annotation-pos-icon.circle-icon {
  border-color: white;
}

.new-annotation-window {
  width: 100%;
}

.new-annotation-window .title {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}

.new-annotation-window .description {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}

.new-annotation-window .bottom {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.new-annotation-window .ant-tabs-nav .ant-tabs-tab {
  font-size: 13px;
  font-weight: bold;
  margin: 0px 7px 0 0;
  padding: 7px 7px;
}

.new-annotation-window .ant-form-item-label {
  line-height: 25px;
}

.new-annotation-window .ant-tabs-bar {
  margin: 0 0 8px 0;
}

.new-annotation-window .ant-form {
  padding: 5px;
}

.new-annotation-photo-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 17vmin;
  justify-content: center;
  margin-bottom: 1vmin;
}

.new-annotation-photo-container .backward-arrow,
.annotation-list-container .backward-arrow {
  width: 15%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
}

.new-annotation-photo-container .new-annotation-photo {
  width: 70%;
  height: 100%;
}

.new-annotation-photo-container .new-annotation-photo img {
  width: 100%;
}

.new-annotation-photo-container .forward-arrow,
.annotation-list-container .forward-arrow {
  width: 15%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
}

.new-annotation-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.new-annotation-preview-container .new-annotation-preview-photo,
.annotation-preview-window .annotation-preview-photo {
  width: 90%;
}

.new-annotation-preview-container .new-annotation-preview-photo img,
.annotation-preview-window .annotation-preview-photo img {
  width: 100%;
  height: 100%;
}

.new-annotation-preview-container .description,
.annotation-preview-window .description {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}

.annotation-list-container {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.annotation-list-container .annotation-existing-photo {
  width: 70%;
  height: 100%;
}

.annotation-list-container .annotation-existing-photo img {
  width: 100%;
  height: 95%;
}

.annotation-list-container .title,
.annotation-preview-window .title {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  width: 100%;
}

.annotation-preview-window .title button{
  position: absolute;
  right: 5px;
}

.annotation-preview-window {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.filtering-sidebar h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}
