.spinview-annnotation {
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex;

  .spinview-sidebar {
    height: 100%;
    background: #fff;
    display: flex;

    .spinview-sidebar-inner {
      width: 80px;
      border-right: 1px solid #eee;
      height: 100%;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .feature {
        border-bottom: 1px solid #eee;
        padding: 1em 2em 1em;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;
        height: 9vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .icon {
          font-size: 18px;
          color: #555;
          width: 1vw;
        }

        &.active {
          background: #eee;
        }
      }
    }

    .spinview-sidebar-outer {
      background: #fff;
      width: 0;
      border-top: 1px solid #eee;
      height: 100%;
      transition: 0.25s ease-in-out all;
      position: relative;

      &.active {
        border-right: solid rgb(187, 187, 187) 1px;
        width: 350px;
        overflow-y: scroll;
      }

      .spinview-sidebar-toolbar {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 1vw;

        button {
          width: 80%;
          height: 2vw;
        }
      }

      .spinview-annotation-form {
        padding: 25px;

        .ant-form-item-label {
          line-height: 20px;

          label {
            font-size: 12px;
          }
        }

        .ant-form-item .ant-form-item {
          margin-bottom: 5px;
        }

        p {
          margin: 0 auto !important;
          width: 90%;
          font-size: 12px !important;
        }

        .bottom {
          display: flex;
          justify-content: space-around;
        }
      }

      .spinview-annotation-list-item {
        .spinview-annotation-item-view {
          width: 80%;
          margin-left: 10%;
          margin-top: 1vw;
          margin-bottom: 1vw;
          border: 2px solid #916794;
          border-radius: 0.5vw;
          color: rgba(0, 0, 0, 0.85);
          padding: 0.5vw;
          cursor: pointer;

          .spinview-annotation-item-title {
            position: relative;
            .svg-inline--fa.fa-edit {
              position: absolute;
              right: 0;
              width: 1.25em;
              height: 1.25em;
            }

            .svg-inline--fa.fa-trash {
              position: absolute;
              right: 2em;
              width: 1em;
              height: 1.25em;
            }
          }

          .spinview-annotation-item-image {
            width: 100%;
            margin-top: 0.75vw;
            margin-bottom: 0.75vw;

            img {
              width: 95%;
              max-height: 150px;
              min-height: 150px;
              margin-left: 2.5%;
            }
          }

          p {
            font-weight: 700;
            text-align: center;
            margin-bottom: 0.5vw;
          }

          &.active {
            border: 3px solid #1a90ff;
          }
        }
      }
    }
  }

  .spinview-main-view {
    flex-grow: 1;
    background: #1c1c21;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .view-switch-button {
      position: absolute;
      right: 2.5%;
      top: 2.5%;
    }

    .view-back-button {
      position: absolute;
      left: 2.5%;
      top: 2.5%;
    }

    .save-reorder-button {
      width: 5%;
      position: absolute;
      bottom: 2.5%;
    }

    .spinview-360view-reorder {
      position: relative;
      height: 85%;
      width: 80%;
      display: none;
      align-items: center;

      .spinview-360view-reorder-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;

        & > div {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%;
        }

        .card.vin-card {
          width: 250px;
          height: auto;

          .photo-section {
            width: 100%;
            display: flex;
            flex-direction: column;

            img {
              width: 100%;
              object-fit: contain;
              max-height: 200px;
            }

            .first-image-button {
              margin-left: auto;
              margin-right: auto;
              margin-top: 0.5vw;
              margin-bottom: 0.5vw;
            }
          }
        }
      }

      .spinview-360view-reorder-wrapper::-webkit-scrollbar {
        width: 10px;
      }

      .spinview-360view-reorder-wrapper::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      .spinview-360view-reorder-wrapper::-webkit-scrollbar-thumb {
        background: #888; 
      }
      .spinview-360view-reorder-wrapper::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .spinview-360view-editor {
      position: absolute;
      left: 20%;
      top: 10%;
      height: 80%;
      width: 60%;
      display: none;

      .spinview-360view-editor-wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        #spinview-360view {
          position: relative;
          margin: 0 auto;

          .annotation-frame-done {
            position: absolute;
            right: 5%;
            top: 5%;
            z-index: 2000;
          }

          .spinview-annotation-pos {
            cursor: pointer;
            position: absolute;
            z-index: 2000;

            .spinview-annotation-pos-icon {
              width: 12.5px;
              height: 12.5px;
              background-color: rgb(0, 223, 252);
              border-radius: 50%;
            }

            &.active {
              .spinview-annotation-pos-icon {
                border: 2px solid white;
              }
            }
          }
        }

        .spinview-info {
          display: flex;

          .spinview-frameindex-label {
            display: inline-block;
            position: relative;
            width: 60px;
            color: #fff;
            line-height: 20px;
            text-align: center;
            border-radius: 3px;
            background: #2c3e50;
            padding: 5px 10px;
          }

          .spinview-edit-message {
            flex: 1;
            text-align: center;
            padding: 5px 10px;
            color: #fff;
            line-height: 20px;
          }
        }
      }
    }

    .visible {
      visibility: visible;
      opacity: 1;
      display: block;
    }

    .spinview-loading-container {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
