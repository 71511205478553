.editor-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.editor-footer > * {
  margin-right: 1em;
}

.editor-footer .btn-cancel {
  align-content: flex-end;
}

.editor-footer p {
  font-weight: 800;
  font-size: 0.8em;
  color: #555;
}

.editor-footer-left {
  display: flex;
}

.editor-footer-left > * {
  margin-right: 1em;
}

.editor-footer .branding-footer{
  width: 100%;
}