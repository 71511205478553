//variables
$boxborderColor: #dedede;
$titlebackgrondColor: #dedede;

.tenant-admin-wrapper {
    margin-top: 50px;
}
.tenant-user-wrapper {
    margin-top: 10px;
    border: 1px solid $boxborderColor;
    .empty-users-msg {
        text-align: center;
        font-size: 14px;
    }
    .userlist-title {
        text-align: center;
        font-size: 30px;
        padding: 10px 0;
        background-color: $titlebackgrondColor;
    }
    .tenant-user-action {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }
}
.brandingtask-wrapper {
    margin-top: 10px;
    border: 1px solid $boxborderColor;
    .empty-brandingtask-msg {
        text-align: center;
        font-size: 14px;
    }
    .brandingtask-title {
        text-align: center;
        font-size: 30px;
        padding: 10px 0;
        background-color: $titlebackgrondColor;
    }
    .brandingtask-action {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }
}
.tenant-setting-wrapper {
    margin-top: 10px;
    border: 1px solid $boxborderColor;
    .setting-title {
        text-align: center;
        font-size: 30px;
        padding: 10px 0;
        background-color: $titlebackgrondColor;
    }
    h3 {
        text-align: center;
    }
    .tenant-setting-content {
        margin-top: 20px;
        .aspect-ratio {
            display: flex;
            margin-top: 20px;
            .radio {
                display: -webkit-flexbox;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex: 1 0 auto;
                -webkit-flex-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                text-align: center;
                margin-left: 10px;
                margin-right: 10px;
                border-radius: 5px;
                height: 2em;
                font-size: 24px;
                span {
                    margin-right: auto;
                }
            }
        }
        .switch {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 20px;
            button {
                width: 80px;
                height: 40px;
                &::after {
                    width: 38px;
                    height: 35px;
                }
            }
        }
    }
}
.password-toggle {
    margin-bottom: 20px;
    .desc {
        color: #000000;
    }
    .desc::before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}
