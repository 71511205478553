@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
#dashboard {
    width: 100%;
    height: 100%;
}

.dashboard-card-wrapper {
    position: relative;
    margin: .5vw;
}
.dashboard-card-wrapper button {
    display: none;
    position: absolute;
    right: 5px;
    top: 5px;
}

.dashboard-card-wrapper:hover button {
    display: block;
}

.dashboard-card-wrapper .card {
    margin: 0;
}

.vin-card a {
    height: 100%;
    width: 100%;
}


.vin-card.new-session-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vin-card.new-session-card .card-title {
    padding-top: 1em;
}

.vin-card.new-session-card svg {
    color: #111;
}

.vin-card .body-section {
    padding: 12px;
}

.vin-card .body-section h4,
.vin-card .body-section h5 {
    margin: 0;
}

.card-photo-section {
    width: 300px;
    height: calc(300px * 3/4);
    border: 1px solid #eee;
}

.card-photo-section img {
    height: 100%;
    width: 100%;
}

.search-header {
    background: #fff;
    padding: 1em 50px;
}

.search-header h4 {
    font-weight: 700;
    font-size: 13px;
}

#search.search-header .ant-select-auto-complete.ant-select-lg .ant-input {
    background: #f5f5f5;
    border: none;
    padding-left: 0;
    height: 60px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: 0;
    box-shadow: none;
}

#search.search-header .ant-select-auto-complete.ant-select-lg .ant-input:focus {
    border: none !important;
}

#search.search-header .ant-input-group-addon {
    background: #f5f5f5;
    border: none;
    padding: 0 1em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 21px;
    cursor: default;
}

#search.search-header .stats {
    padding-top: 1em;
    display: flex;
    flex-direction: row;
}

#search.search-header .stats h5 {
    margin-right: 12px;
}

.vehicle-results {
    display: flex;
    flex-direction: row;
    padding: 2em 0;
    flex-wrap: wrap;
}

.search-vins-footer {
  display: flex;
  justify-content: space-between;
}

.search-vins-footer .ant-pagination {
  padding: .5em 0 0 0;
}

.search-vins-footer .ant-pagination .ant-pagination-total-text {
  font-weight: 700;
  color: #949494;
  font-size: .83em;
}
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 4em);
    margin: auto;
    background: #fff;
}

.login-page input {
    width: 100%;
}

.login-page h1 {
    font-weight: 700;
    margin-bottom: 0;
}

.login-form {
    width: 100%;
    padding: 0;
}

.login-form-bottom .ant-form-item-children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.row.login-row {
    width: 500px;
    min-width: 500px;
    height: 70vh;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
}

.login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login-logo img {
    height: 40px;
    width: auto;
    position: relative;
    bottom: 1em;
}


/*.welcome {*/
    /*background: url(https://www.ovad.com/uploads/1/1/9/0/119051462/stage-photo-dome1-1300px-846px_orig.jpg);*/
    /*height: 100%;*/
    /*background-size: auto;*/
    /*background-position: -126px 0;*/
    /*width: 100%;*/
/*}*/

.classifications {
    width: 70%;
    margin: 0 auto;
}
.add-classification-row {
    display: flex;
    justify-content: flex-end;
    padding: 1em 0;
}
.ant-table-wrapper {
    width: 100%;
    margin: 0 auto;
}

.ant-table-wrapper thead {
    font-weight: 700;
    color: #111;
}

.ant-table-wrapper tbody tr {
    background: #fff;
}
.session-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  .session-container .spinview-overlay {
    position: absolute;
    height: 16vw;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    margin-top: 50px;
    z-index: -1; }
  .session-container .session-view-selection {
    position: absolute;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 1.25%; }
  .session-container .container-full-width {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .session-container .button-row {
    margin-top: 1vw; }

.preview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .preview-container .spinview-preview {
    width: 45vw;
    min-height: 27.5vw;
    margin-top: 1.5%;
    margin-bottom: 1.5%; }
    .preview-container .spinview-preview iframe {
      outline: none;
      border: none; }
  .preview-container .panoview-preview {
    width: 45vw;
    min-height: 27.5vw;
    margin-top: 1.5%;
    margin-bottom: 1.5%; }
    .preview-container .panoview-preview iframe {
      outline: none;
      border: none; }

.classifications {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.session-photo-section {
  height: 400px;
  width: 100%;
}

.all-photos {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.before,
.after {
  position: relative;
  z-index: 0;
  color: #eee;
  cursor: pointer;
}

.before .back-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 20px;
}

.after .forward-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
}

.session-photo-section .before,
.session-photo-section .after {
  height: 300px;
  width: 50%;
  background: #999;
  overflow-x: hidden;
  display: flex;
}

.session-photo-section .current-photo {
  height: 400px;
  width: 530px;
  background: #111;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  z-index: 1;
}
.before-image,
.after-image {
  height: 300px;
  width: 400px;
}

.classifications-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  margin-top: 2em;
}

.classification {
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.classification h4 {
  margin-top: 1em;
  font-weight: 700;
}

.card.classification {
  height: 150px;
  width: 150px;
}

.classification .classification-thumbnail img {
  height: auto;
  width: 150px;
}

.current-photo-overlay {
  height: 100%;
  width: 100%;
  position: relative;
  color: #eee;
}

.current-photo-footer {
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.photos-remaining {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-weight: 800;
  color: #eee;
}

.classification-type {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 800;
  color: #eee;
}

.no-photos {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-weight: 800;
  color: #eee;
}

.before-overlay,
.after-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.8;
}

.hidden {
  display: none;
}
.modal-main-container {
  width: 100%;
  height: 100%;
}
.modal-current-photo {
  height: 400px;
  width: 530px;
  background: #111;
}
.modal-image-options {
  display: flex;
  flex-direction: row;
}

.session-submenu-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.session-submenu {
  width: 100%;
  height: 50px;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 1px solid rgb(200, 200, 200);
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-submenu-item {
  text-decoration: underline;
  cursor: pointer;
  color: #111;
  font-size: 13px;
  font-weight: 700;
  margin-right: 40px;
  line-height: 30px;
}

.session-submenu ul {
  height: 30px;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  list-style-type: none;
  display: flex;
}
.requirements-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#finalize .search-vins {
  margin-bottom: 2em; }

#finalize .vin-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; }

#finalize .vin-card .body-section {
  margin-top: 5px;
  padding: 5px;
  text-align: center; }

#finalize > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly; }

.vin-card .body-section h4,
.vin-card .body-section h5 {
  margin: 0; }

#finalize .vin-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

#finalize .card.vin-card {
  width: 350px;
  height: auto; }

#finalize .card.vin-card .photo-item {
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */ }

.view-label {
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center; }

.spinview-preview {
  width: 50%;
  min-height: 65%;
  margin-top: 1.5%;
  margin-bottom: 1.5%; }
  .spinview-preview iframe {
    outline: none;
    border: none; }

.panoview-preview {
  width: 50%;
  min-height: 65%;
  margin-top: 1.5%;
  margin-bottom: 1.5%; }
  .panoview-preview iframe {
    outline: none;
    border: none; }

.circle-loader {
  margin-bottom: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  -webkit-animation: loader-spin 1.2s infinite linear;
          animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 3em;
  height: 3em; }

.circle-loader:hover {
  cursor: pointer; }

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out; }

.error {
  display: inline;
  font-size: 2em; }
  .error.draw:after {
    -webkit-animation-duration: 1200ms;
            animation-duration: 1200ms;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-name: checkmark;
            animation-name: checkmark; }
  .error:after {
    opacity: 1;
    height: 1.5em;
    width: 0.75em;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    content: 'x';
    left: 0.75em;
    top: 1.5em;
    position: absolute; }

.checkmark {
  display: inline; }
  .checkmark.draw:after {
    -webkit-animation-duration: 1200ms;
            animation-duration: 1200ms;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-name: checkmark;
            animation-name: checkmark;
    -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg); }
  .checkmark:after {
    opacity: 1;
    height: 1.5em;
    width: 0.75em;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    border-right: 3px solid #5cb85c;
    border-top: 3px solid #5cb85c;
    content: '';
    left: 0.75em;
    top: 1.5em;
    position: absolute; }

@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 0.75em;
    opacity: 1; }
  40% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1; }
  100% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1; } }

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 0.75em;
    opacity: 1; }
  40% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1; }
  100% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1; } }

.filtering-sidebar canvas {
    margin: 0.2em 0;
    cursor: pointer !important;
}

.filter-preview {
    position: relative;
    width: 96%;
    margin-bottom: 5%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    background-color: rgb(195, 195, 205)
}

.filter-preview:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.filter-container {
    padding-bottom: 10px;
}

.filter-preview h4 {
    font-size: 17px;
    font-weight: bold;
}
.filter-preview canvas {
    border-radius: 5px;
}

.brandupload-form {
  padding: 20px;
}

.brandupload-form h2 {
  font-size: 18px;
  font-weight: bold;
}

.brandupload-form div {
  display: flex;
  align-items: flex-start !important;
  width: 100%;
}

.brandupload-form .ant-form-item-children {
  width: 100%;
}

.brandupload-form p {
  margin: 0 auto !important;
  width: 90%;
  font-size: 13px !important;
}

.brandupload-form .ant-form {
  width: 100%;
}

.brandupload-form .ant-upload-list-item-info>span {
  width: 100%;
}

.branding-list h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.branding-item {
  display: block;
  cursor: pointer;
  width: 96%;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  background-color: rgb(195, 195, 205)
}

.branding-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.branding-item-container {
  padding: 10px 5px;
}

.branding-item .branding-item-img {
  width: 90%;
  border-radius: 5px;
}

.branding-item-img.active {
  border: 2px solid rgb(28, 28, 33);
}

.branding-item h4 {
  font-size: 17px;
  font-weight: bold;
}

.branding-item.selected {
  border: 2px solid rgb(24, 144, 255);
}
.photos-annotation-list-item {
  display: flex;
  flex-direction: column; }
  .photos-annotation-list-item .photos-annotation-item-view {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    border: 2px solid #916794;
    border-radius: 0.5vw;
    color: rgba(0, 0, 0, 0.85);
    padding: 0.5vw;
    cursor: pointer; }
    .photos-annotation-list-item .photos-annotation-item-view .photos-annotation-item-title {
      width: 100%;
      position: relative; }
      .photos-annotation-list-item .photos-annotation-item-view .photos-annotation-item-title .svg-inline--fa.fa-edit {
        position: absolute;
        right: 0;
        width: 1.25em;
        height: 1.25em; }
      .photos-annotation-list-item .photos-annotation-item-view .photos-annotation-item-title .svg-inline--fa.fa-trash {
        position: absolute;
        right: 2em;
        width: 1em;
        height: 1.25em; }
    .photos-annotation-list-item .photos-annotation-item-view .photos-annotation-item-image {
      width: 100%;
      margin-top: 0.75vw;
      margin-bottom: 0.75vw; }
      .photos-annotation-list-item .photos-annotation-item-view .photos-annotation-item-image img {
        width: 95%;
        max-height: 150px;
        min-height: 150px;
        margin-left: 2.5%; }
    .photos-annotation-list-item .photos-annotation-item-view p {
      font-weight: 700;
      text-align: center;
      margin-bottom: 0.5vw; }
    .photos-annotation-list-item .photos-annotation-item-view.active {
      border: 3px solid #1a90ff; }
  .photos-annotation-list-item .new-annotation-window {
    padding: 10px; }
    .photos-annotation-list-item .new-annotation-window .ant-form-item {
      margin-bottom: 7px; }
    .photos-annotation-list-item .new-annotation-window .ant-upload-text {
      font-size: 12.5px; }
    .photos-annotation-list-item .new-annotation-window .new-annotation-photo-container svg {
      width: 0.35em; }

.nav {
  height: 5vh;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
}

.nav-right {
  margin-right: 75px;
}

.editor-logo {
  text-decoration: none;
  color: #1c1c21;
}

.editor-content {
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.sidebar {
  height: 100%;
  background: #fff;
  display: flex;
}

.sidebar-inner {
  width: 80px;
  border-right: 1px solid #eee;
  height: 100%;
}

.sidebar-outer {
  background: #eee;
  width: 0;
  border-top: 1px solid #eee;
  height: 100%;
  transition: 0.25s ease-in-out all;
  position: relative;
}

.sidebar-outer .branding-upload-image-btn {
  position: fixed;
  top: 93%;
  width: 8%;
}

.sidebar-outer .branding-cancel-upload-btn {
  position: fixed;
  top: 93%;
  width: 8%;
}

.sidebar-outer.active {
  border-left: solid rgb(187, 187, 187) 1px;
  width: 260px;
  overflow-y: scroll;
}

.sidebar-outer.active .filtering-sidebar,
.sidebar-outer.active .filtering-sidebar div,
.sidebar-outer.active .branding-sidebar,
.sidebar-outer.active .branding-sidebar div {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.sidebar-outer.active h3 {
  margin: 1em 0 0 0;
}

.sidebar-outer.active h4 {
  margin: 0.5em 0 0.2em 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul:first-child {
  border-top: 1px solid #eee;
}

.sidebar li {
  border-bottom: 1px solid #eee;
  padding: 1em 2em 1em;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 9vh;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar li:hover {
  background: #eee;
}

.sidebar li:hover .sidebar-tooltip {
  display: flex;
  flex-direction: column;
}

.sidebar li:hover .sidebar-tooltip.hide {
  display: none;
}

.sidebar-tooltip {
  position: absolute;
  right: -160px;
  width: 150px;
  background: #fff;
  z-index: 1;
  display: none;
  border-radius: 2px;
  box-shadow: rgba(24, 53, 88, 0.13) 0px 2px 2px 1px;
}

.sidebar-tooltip-body {
  padding: 1em;
}

.sidebar-tooltip::before {
  position: absolute;
  bottom: calc(50% - 5px);
  left: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid rgba(255, 255, 255, 1);
  content: '';
}

.sidebar-tooltip h5 {
  font-size: 1.2em;
  font-weight: 900;
  margin: 0 0 0.2em;
}

.sidebar-tooltip p {
  font-size: 0.9em;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.sidebar li .icon {
  margin-right: 12px;
  font-size: 18px;
  color: #555;
}

.sidebar .feature.active {
  background: #eee;
}

.editor-container {
  flex-grow: 1;
  /*background: #16161a;*/
  background: #1c1c21;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.editor-container footer button {
  margin-right: 1em;
  font-size: 0.75em;
}

.editor {
  display: inline-grid;
  height: 90%;
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.canvas-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
}

.editor-container footer {
  padding: 1em;
  background: #fff;
  height: 10%;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  color: black;
  font-size: 18px;
}

.photo-loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}

.editor .canvas-wrapper.loading {
  opacity: 0;
}

.editor .canvas-wrapper canvas {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
  max-height: 100%;
}

.editor .canvas-wrapper .cropper-container {
  z-index: 1;
}

.hidden {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.editor-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.editor-footer > * {
  margin-right: 1em;
}

.editor-footer .btn-cancel {
  align-content: flex-end;
}

.editor-footer p {
  font-weight: 800;
  font-size: 0.8em;
  color: #555;
}

.editor-footer-left {
  display: flex;
}

.editor-footer-left > * {
  margin-right: 1em;
}

.editor-footer .branding-footer{
  width: 100%;
}
.annotation-pos {
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.annotation-window {
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 20;
  padding: 10px;
}

.annotation-window::before {
  position: absolute;
  top: 17px;
  right: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(255, 255, 255, 1);
  content: '';
}

.annotation-pos-icon.circle-icon {
  width: 15px;
  height: 15px;
  background-color: rgb(0, 223, 252);
  border: 2px solid rgb(64, 255, 255);
  border-radius: 50%;
}

.annotation-pos.active .annotation-pos-icon.circle-icon {
  border-color: white;
}

.new-annotation-window {
  width: 100%;
}

.new-annotation-window .title {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}

.new-annotation-window .description {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}

.new-annotation-window .bottom {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.new-annotation-window .ant-tabs-nav .ant-tabs-tab {
  font-size: 13px;
  font-weight: bold;
  margin: 0px 7px 0 0;
  padding: 7px 7px;
}

.new-annotation-window .ant-form-item-label {
  line-height: 25px;
}

.new-annotation-window .ant-tabs-bar {
  margin: 0 0 8px 0;
}

.new-annotation-window .ant-form {
  padding: 5px;
}

.new-annotation-photo-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 17vmin;
  justify-content: center;
  margin-bottom: 1vmin;
}

.new-annotation-photo-container .backward-arrow,
.annotation-list-container .backward-arrow {
  width: 15%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.new-annotation-photo-container .new-annotation-photo {
  width: 70%;
  height: 100%;
}

.new-annotation-photo-container .new-annotation-photo img {
  width: 100%;
}

.new-annotation-photo-container .forward-arrow,
.annotation-list-container .forward-arrow {
  width: 15%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.new-annotation-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.new-annotation-preview-container .new-annotation-preview-photo,
.annotation-preview-window .annotation-preview-photo {
  width: 90%;
}

.new-annotation-preview-container .new-annotation-preview-photo img,
.annotation-preview-window .annotation-preview-photo img {
  width: 100%;
  height: 100%;
}

.new-annotation-preview-container .description,
.annotation-preview-window .description {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}

.annotation-list-container {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.annotation-list-container .annotation-existing-photo {
  width: 70%;
  height: 100%;
}

.annotation-list-container .annotation-existing-photo img {
  width: 100%;
  height: 95%;
}

.annotation-list-container .title,
.annotation-preview-window .title {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  width: 100%;
}

.annotation-preview-window .title button{
  position: absolute;
  right: 5px;
}

.annotation-preview-window {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.filtering-sidebar h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tenant-admin-wrapper {
  margin-top: 50px; }

.tenant-user-wrapper {
  margin-top: 10px;
  border: 1px solid #dedede; }
  .tenant-user-wrapper .empty-users-msg {
    text-align: center;
    font-size: 14px; }
  .tenant-user-wrapper .userlist-title {
    text-align: center;
    font-size: 30px;
    padding: 10px 0;
    background-color: #dedede; }
  .tenant-user-wrapper .tenant-user-action {
    display: flex;
    justify-content: flex-end;
    padding: 10px; }

.brandingtask-wrapper {
  margin-top: 10px;
  border: 1px solid #dedede; }
  .brandingtask-wrapper .empty-brandingtask-msg {
    text-align: center;
    font-size: 14px; }
  .brandingtask-wrapper .brandingtask-title {
    text-align: center;
    font-size: 30px;
    padding: 10px 0;
    background-color: #dedede; }
  .brandingtask-wrapper .brandingtask-action {
    display: flex;
    justify-content: flex-end;
    padding: 10px; }

.tenant-setting-wrapper {
  margin-top: 10px;
  border: 1px solid #dedede; }
  .tenant-setting-wrapper .setting-title {
    text-align: center;
    font-size: 30px;
    padding: 10px 0;
    background-color: #dedede; }
  .tenant-setting-wrapper h3 {
    text-align: center; }
  .tenant-setting-wrapper .tenant-setting-content {
    margin-top: 20px; }
    .tenant-setting-wrapper .tenant-setting-content .aspect-ratio {
      display: flex;
      margin-top: 20px; }
      .tenant-setting-wrapper .tenant-setting-content .aspect-ratio .radio {
        display: -webkit-flexbox;
        display: flex;
        flex: 1 0 auto;
        -webkit-flex-align: center;
        align-items: center;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 5px;
        height: 2em;
        font-size: 24px; }
        .tenant-setting-wrapper .tenant-setting-content .aspect-ratio .radio span {
          margin-right: auto; }
    .tenant-setting-wrapper .tenant-setting-content .switch {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px; }
      .tenant-setting-wrapper .tenant-setting-content .switch button {
        width: 80px;
        height: 40px; }
        .tenant-setting-wrapper .tenant-setting-content .switch button::after {
          width: 38px;
          height: 35px; }

.password-toggle {
  margin-bottom: 20px; }
  .password-toggle .desc {
    color: #000000; }
  .password-toggle .desc::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*'; }

.spinview-annnotation {
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex; }
  .spinview-annnotation .spinview-sidebar {
    height: 100%;
    background: #fff;
    display: flex; }
    .spinview-annnotation .spinview-sidebar .spinview-sidebar-inner {
      width: 80px;
      border-right: 1px solid #eee;
      height: 100%; }
      .spinview-annnotation .spinview-sidebar .spinview-sidebar-inner ul {
        list-style-type: none;
        padding: 0;
        margin: 0; }
      .spinview-annnotation .spinview-sidebar .spinview-sidebar-inner .feature {
        border-bottom: 1px solid #eee;
        padding: 1em 2em 1em;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;
        height: 9vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-inner .feature .icon {
          font-size: 18px;
          color: #555;
          width: 1vw; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-inner .feature.active {
          background: #eee; }
    .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer {
      background: #fff;
      width: 0;
      border-top: 1px solid #eee;
      height: 100%;
      transition: 0.25s ease-in-out all;
      position: relative; }
      .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer.active {
        border-right: solid #bbbbbb 1px;
        width: 350px;
        overflow-y: scroll; }
      .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-sidebar-toolbar {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 1vw; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-sidebar-toolbar button {
          width: 80%;
          height: 2vw; }
      .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-form {
        padding: 25px; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-form .ant-form-item-label {
          line-height: 20px; }
          .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-form .ant-form-item-label label {
            font-size: 12px; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-form .ant-form-item .ant-form-item {
          margin-bottom: 5px; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-form p {
          margin: 0 auto !important;
          width: 90%;
          font-size: 12px !important; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-form .bottom {
          display: flex;
          justify-content: space-around; }
      .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view {
        width: 80%;
        margin-left: 10%;
        margin-top: 1vw;
        margin-bottom: 1vw;
        border: 2px solid #916794;
        border-radius: 0.5vw;
        color: rgba(0, 0, 0, 0.85);
        padding: 0.5vw;
        cursor: pointer; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view .spinview-annotation-item-title {
          position: relative; }
          .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view .spinview-annotation-item-title .svg-inline--fa.fa-edit {
            position: absolute;
            right: 0;
            width: 1.25em;
            height: 1.25em; }
          .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view .spinview-annotation-item-title .svg-inline--fa.fa-trash {
            position: absolute;
            right: 2em;
            width: 1em;
            height: 1.25em; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view .spinview-annotation-item-image {
          width: 100%;
          margin-top: 0.75vw;
          margin-bottom: 0.75vw; }
          .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view .spinview-annotation-item-image img {
            width: 95%;
            max-height: 150px;
            min-height: 150px;
            margin-left: 2.5%; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view p {
          font-weight: 700;
          text-align: center;
          margin-bottom: 0.5vw; }
        .spinview-annnotation .spinview-sidebar .spinview-sidebar-outer .spinview-annotation-list-item .spinview-annotation-item-view.active {
          border: 3px solid #1a90ff; }
  .spinview-annnotation .spinview-main-view {
    flex-grow: 1;
    background: #1c1c21;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center; }
    .spinview-annnotation .spinview-main-view .view-switch-button {
      position: absolute;
      right: 2.5%;
      top: 2.5%; }
    .spinview-annnotation .spinview-main-view .view-back-button {
      position: absolute;
      left: 2.5%;
      top: 2.5%; }
    .spinview-annnotation .spinview-main-view .save-reorder-button {
      width: 5%;
      position: absolute;
      bottom: 2.5%; }
    .spinview-annnotation .spinview-main-view .spinview-360view-reorder {
      position: relative;
      height: 85%;
      width: 80%;
      display: none;
      align-items: center; }
      .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto; }
        .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper > div {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%; }
        .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper .card.vin-card {
          width: 250px;
          height: auto; }
          .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper .card.vin-card .photo-section {
            width: 100%;
            display: flex;
            flex-direction: column; }
            .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper .card.vin-card .photo-section img {
              width: 100%;
              object-fit: contain;
              max-height: 200px; }
            .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper .card.vin-card .photo-section .first-image-button {
              margin-left: auto;
              margin-right: auto;
              margin-top: 0.5vw;
              margin-bottom: 0.5vw; }
      .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper::-webkit-scrollbar {
        width: 10px; }
      .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper::-webkit-scrollbar-track {
        background: #f1f1f1; }
      .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper::-webkit-scrollbar-thumb {
        background: #888; }
      .spinview-annnotation .spinview-main-view .spinview-360view-reorder .spinview-360view-reorder-wrapper::-webkit-scrollbar-thumb:hover {
        background: #555; }
    .spinview-annnotation .spinview-main-view .spinview-360view-editor {
      position: absolute;
      left: 20%;
      top: 10%;
      height: 80%;
      width: 60%;
      display: none; }
      .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper {
        width: 100%;
        height: 100%;
        position: relative; }
        .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper #spinview-360view {
          position: relative;
          margin: 0 auto; }
          .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper #spinview-360view .annotation-frame-done {
            position: absolute;
            right: 5%;
            top: 5%;
            z-index: 2000; }
          .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper #spinview-360view .spinview-annotation-pos {
            cursor: pointer;
            position: absolute;
            z-index: 2000; }
            .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper #spinview-360view .spinview-annotation-pos .spinview-annotation-pos-icon {
              width: 12.5px;
              height: 12.5px;
              background-color: #00dffc;
              border-radius: 50%; }
            .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper #spinview-360view .spinview-annotation-pos.active .spinview-annotation-pos-icon {
              border: 2px solid white; }
        .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper .spinview-info {
          display: flex; }
          .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper .spinview-info .spinview-frameindex-label {
            display: inline-block;
            position: relative;
            width: 60px;
            color: #fff;
            line-height: 20px;
            text-align: center;
            border-radius: 3px;
            background: #2c3e50;
            padding: 5px 10px; }
          .spinview-annnotation .spinview-main-view .spinview-360view-editor .spinview-360view-editor-wrapper .spinview-info .spinview-edit-message {
            flex: 1 1;
            text-align: center;
            padding: 5px 10px;
            color: #fff;
            line-height: 20px; }
    .spinview-annnotation .spinview-main-view .visible {
      visibility: visible;
      opacity: 1;
      display: block; }
    .spinview-annnotation .spinview-main-view .spinview-loading-container {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      background: rgba(0, 0, 0, 0.3); }

.panoview-annnotation {
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex; }
  .panoview-annnotation .panoview-sidebar {
    height: 100%;
    background: #fff;
    display: flex; }
    .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer {
      background: #fff;
      width: 0;
      border-top: 1px solid #eee;
      height: 100%;
      transition: 0.25s ease-in-out all;
      position: relative; }
      .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer.active {
        border-right: solid #bbbbbb 1px;
        width: 350px;
        overflow-y: scroll; }
      .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-sidebar-toolbar {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 1vw; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-sidebar-toolbar button {
          width: 80%;
          height: 2vw; }
      .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-form {
        padding: 25px; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-form .ant-form-item-label {
          line-height: 20px; }
          .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-form .ant-form-item-label label {
            font-size: 12px; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-form .ant-form-item .ant-form-item {
          margin-bottom: 5px; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-form p {
          margin: 0 auto !important;
          width: 90%;
          font-size: 12px !important; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-form .bottom {
          display: flex;
          justify-content: space-around; }
      .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view {
        width: 80%;
        margin-left: 10%;
        margin-top: 1vw;
        margin-bottom: 1vw;
        border: 2px solid #916794;
        border-radius: 0.5vw;
        color: rgba(0, 0, 0, 0.85);
        padding: 0.5vw;
        cursor: pointer; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view .panoview-annotation-item-title {
          position: relative; }
          .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view .panoview-annotation-item-title .svg-inline--fa.fa-edit {
            position: absolute;
            right: 0;
            width: 1.25em;
            height: 1.25em; }
          .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view .panoview-annotation-item-title .svg-inline--fa.fa-trash {
            position: absolute;
            right: 2em;
            width: 1em;
            height: 1.25em; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view .panoview-annotation-item-image {
          width: 100%;
          margin-top: 0.75vw;
          margin-bottom: 0.75vw; }
          .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view .panoview-annotation-item-image img {
            width: 95%;
            max-height: 150px;
            min-height: 150px;
            margin-left: 2.5%; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view p {
          font-weight: 700;
          text-align: center;
          margin-bottom: 0.5vw; }
        .panoview-annnotation .panoview-sidebar .panoview-sidebar-outer .panoview-annotation-list-item .panoview-annotation-item-view.active {
          border: 3px solid #1a90ff; }
  .panoview-annnotation .panoview-main-view {
    flex-grow: 1;
    background: #1c1c21;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center; }
    .panoview-annnotation .panoview-main-view .view-switch-button {
      position: absolute;
      right: 2.5%;
      top: 2.5%; }
    .panoview-annnotation .panoview-main-view .view-back-button {
      position: absolute;
      left: 2.5%;
      top: 2.5%; }
    .panoview-annnotation .panoview-main-view .view-center-button {
      position: absolute;
      top: 92.5%; }
    .panoview-annnotation .panoview-main-view .panoview-360view-wrapper {
      height: 70%;
      width: 60%;
      position: relative;
      align-items: center; }
      .panoview-annnotation .panoview-main-view .panoview-360view-wrapper #panoview-360view {
        position: relative;
        width: 100%;
        height: 100%;
        outline: none;
        overflow: hidden; }
        .panoview-annnotation .panoview-main-view .panoview-360view-wrapper #panoview-360view canvas {
          width: 100%;
          height: 100%; }
        .panoview-annnotation .panoview-main-view .panoview-360view-wrapper #panoview-360view .annotation-frame-done {
          position: absolute;
          right: 5%;
          top: 5%; }
        .panoview-annnotation .panoview-main-view .panoview-360view-wrapper #panoview-360view .panoview-annotation-pos {
          cursor: pointer;
          position: absolute; }
          .panoview-annnotation .panoview-main-view .panoview-360view-wrapper #panoview-360view .panoview-annotation-pos .panoview-annotation-pos-icon {
            width: 12.5px;
            height: 12.5px;
            background-color: #00dffc;
            border-radius: 50%; }
          .panoview-annnotation .panoview-main-view .panoview-360view-wrapper #panoview-360view .panoview-annotation-pos.active .panoview-annotation-pos-icon {
            border: 2px solid white; }
      .panoview-annnotation .panoview-main-view .panoview-360view-wrapper .panoview-info {
        display: flex; }
        .panoview-annnotation .panoview-main-view .panoview-360view-wrapper .panoview-info .panoview-edit-message {
          flex: 1 1;
          text-align: center;
          padding: 5px 10px;
          color: #fff;
          line-height: 20px;
          height: 30px; }
    .panoview-annnotation .panoview-main-view .panoview-loading-container {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      background: rgba(0, 0, 0, 0.3); }

.content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%; }

.metric-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%; }
  .metric-content-container .metric-content-section {
    width: 95%;
    margin-top: 2em;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    background-color: #c3c3cd4d;
    padding: 1.5em 1.5em 2em 1.5em; }
    .metric-content-container .metric-content-section .metric-section-title {
      font-size: 1.75em;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 1em; }
    .metric-content-container .metric-content-section .metric-card-container {
      display: flex;
      justify-content: space-around; }
      .metric-content-container .metric-content-section .metric-card-container .metric-card-body {
        position: relative;
        width: 23%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px; }
        .metric-content-container .metric-content-section .metric-card-container .metric-card-body .metric-icon-container {
          position: absolute;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          left: 5%;
          top: 1em;
          width: 5.5em;
          height: 5.5em;
          border-radius: 5px;
          background-size: 80% 80%;
          background-position: center;
          background-repeat: no-repeat; }
        .metric-content-container .metric-content-section .metric-card-container .metric-card-body .metric-info-container {
          margin-top: 1.5em; }
          .metric-content-container .metric-content-section .metric-card-container .metric-card-body .metric-info-container .metric-title {
            width: 70%;
            margin-left: 30%;
            position: relative;
            right: 0px;
            text-align: right;
            padding-right: 1.25em;
            text-transform: capitalize;
            font-size: 1.15em;
            font-weight: bold;
            height: 2.5em; }
          .metric-content-container .metric-content-section .metric-card-container .metric-card-body .metric-info-container .metric-value {
            text-align: right;
            padding-right: 0.75em;
            font-size: 2.75em;
            font-weight: 700;
            margin-bottom: 0; }
        .metric-content-container .metric-content-section .metric-card-container .metric-card-body .time-selector {
          height: 3.5em;
          display: flex;
          align-items: center;
          justify-content: center; }
          .metric-content-container .metric-content-section .metric-card-container .metric-card-body .time-selector h2 {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.1em;
            font-weight: bold;
            margin-bottom: 0; }
  .metric-content-container .recent-session-container {
    margin-top: 3em;
    width: 95%;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    background-color: #c3c3cd4d;
    padding: 1.5em 1.5em 0em 1.5em; }
    .metric-content-container .recent-session-container .recent-session-title {
      font-size: 1.75em;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0.5em; }
    .metric-content-container .recent-session-container .recent-session-vehicle {
      font-size: 1.25em;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.85); }
      .metric-content-container .recent-session-container .recent-session-vehicle .recent-session-vehicle-subtitle {
        font-weight: bold; }
      .metric-content-container .recent-session-container .recent-session-vehicle .recent-session-vehicle-value {
        margin-right: 0.5em; }
    .metric-content-container .recent-session-container .photo-container {
      width: 75vw;
      display: flex;
      flex-direction: row;
      overflow-x: auto; }
      .metric-content-container .recent-session-container .photo-container .photo-item {
        width: 12vw;
        min-width: 12vw;
        height: 12vw;
        margin-left: 1vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; }
    .metric-content-container .recent-session-container .container-360 {
      width: 75vw;
      display: flex;
      flex-direction: row;
      justify-content: space-around; }
      .metric-content-container .recent-session-container .container-360 .spinview-preview {
        width: 25vw;
        min-height: 15vw;
        margin-top: 1.5%;
        margin-bottom: 1.5%;
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center; }
        .metric-content-container .recent-session-container .container-360 .spinview-preview iframe {
          outline: none;
          border: none; }
      .metric-content-container .recent-session-container .container-360 .panoview-preview {
        width: 25vw;
        min-height: 15vw;
        margin-top: 1.5%;
        margin-bottom: 1.5%;
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2); }
        .metric-content-container .recent-session-container .container-360 .panoview-preview iframe {
          outline: none;
          border: none; }

.metric-sidebar {
  width: 20%;
  background: #0D181C;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .metric-sidebar .sidebar-title-container {
    margin-top: 1.5em;
    margin-bottom: 1em;
    width: 100%;
    height: 4em; }
    .metric-sidebar .sidebar-title-container .title {
      font-size: 2em;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: white; }
  .metric-sidebar .side-card-container-list {
    flex: 1 1; }
    .metric-sidebar .side-card-container-list .site-card-container {
      cursor: pointer;
      position: relative;
      margin: auto;
      margin-bottom: 2em;
      height: 10em;
      width: 80%;
      box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      border-radius: 2.5px;
      background-color: #f4f8fa; }
      .metric-sidebar .side-card-container-list .site-card-container.selected {
        border: 3px solid #0080f8; }
    .metric-sidebar .side-card-container-list .site-card-container:hover {
      background-color: #a9cddb; }
    .metric-sidebar .side-card-container-list .site-card-container h4 {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 1.35em;
      font-weight: bold;
      text-transform: uppercase; }

.clear-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  width: 15px;
  cursor: pointer; }

.password-reset-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4em);
  margin: auto;
  background: #fff;
}

.password-reset-page input {
  width: 100%;
}

.password-reset-page h1 {
  font-weight: 700;
  margin-bottom: 1vw;
  margin-top: 2vw;
}

.password-reset-form {
  width: 100%;
  padding: 0;
  margin-top: 1vw;
  margin-bottom: 2vw;
}

.password-reset-form-bottom .ant-form-item-children {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row.password-reset-row {
  width: 500px;
  min-width: 500px;
  height: 60vh;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.password-reset-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.password-reset-logo img {
  height: 40px;
  width: auto;
  position: relative;
  bottom: 1em;
}

header.jombler-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #DCDCDC;
    height: 80px;
}

.logo {
    height: 100%;
    display: flex;
    align-items: center;
}
.logo img {
    height: 30px;
    width: auto;
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
}

.links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.links ul li {
    padding: 0 0.5em;
}

.links ul a {
    text-decoration: none;
    color: #111;
    font-size: 13px;
    font-weight: 700;
}

.ant-dropdown-trigger {
    cursor: pointer;
}

.jombler-header .ant-dropdown-menu {
    padding: 1em;
}
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background: #f4f8fa; }

.content {
  height: calc(100vh - 80px); }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%; }

@media (max-width: 1250px) {
  .hide-md {
    display: none; } }

@media (max-width: 1000px) {
  .btn-responsive {
    width: 100%;
    margin: 0.5em; } }

@media (min-width: 800px) {
  .float-right-md {
    float: right; } }

.center-hz {
  align-items: center; }

.center {
  align-items: center;
  justify-content: center; }

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1; }

.page-title {
  font-size: 2em;
  font-weight: 700; }

.card-title {
  font-weight: 700;
  color: #111;
  font-size: 0.9em; }

.secondary {
  color: #333;
  font-weight: 700;
  font-size: 0.75em; }

.muted-text {
  color: #949494;
  font-weight: 700; }

#root .ant-input {
  background: #f5f5f5;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: 0;
  box-shadow: none; }

#root .ant-input:focus {
  background: #f5f5f5; }

#root .ant-input-prefix {
  margin-right: 12px; }

.card {
  width: 300px;
  height: 300px;
  background: #fff;
  box-shadow: rgba(24, 53, 88, 0.13) 0px 2px 2px 1px;
  margin: 0.5vw; }

.container {
  padding: 0 50px; }

.ant-modal {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 520px; }

.extract-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center; }

.annotation-new-modal .bottom {
  display: flex;
  justify-content: space-around; }

.annotation-preview-modal .spinview-annotation-item-view, .annotation-preview-modal .panoview-annotation-item-view {
  border-radius: 0.5vw;
  color: rgba(0, 0, 0, 0.85);
  padding: 0.5vw;
  cursor: pointer; }
  .annotation-preview-modal .spinview-annotation-item-view .spinview-annotation-item-title, .annotation-preview-modal .panoview-annotation-item-view .spinview-annotation-item-title {
    position: relative; }
    .annotation-preview-modal .spinview-annotation-item-view .spinview-annotation-item-title .svg-inline--fa.fa-edit, .annotation-preview-modal .panoview-annotation-item-view .spinview-annotation-item-title .svg-inline--fa.fa-edit {
      position: absolute;
      right: 0;
      width: 1.25em;
      height: 1.25em; }
  .annotation-preview-modal .spinview-annotation-item-view .spinview-annotation-item-image, .annotation-preview-modal .panoview-annotation-item-view .spinview-annotation-item-image {
    width: 90%;
    margin-top: 0.75vw;
    margin-bottom: 0.75vw;
    margin-left: 5%; }
    .annotation-preview-modal .spinview-annotation-item-view .spinview-annotation-item-image img, .annotation-preview-modal .panoview-annotation-item-view .spinview-annotation-item-image img {
      width: 100%; }
  .annotation-preview-modal .spinview-annotation-item-view p, .annotation-preview-modal .panoview-annotation-item-view p {
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5vw; }
  .annotation-preview-modal .spinview-annotation-item-view.active, .annotation-preview-modal .active.panoview-annotation-item-view {
    border: 3px solid #10ff00; }

.annotation-preview-modal .panoview-annotation-item-view .panoview-annotation-item-title {
  position: relative; }
  .annotation-preview-modal .panoview-annotation-item-view .panoview-annotation-item-title .svg-inline--fa.fa-edit {
    position: absolute;
    right: 0;
    width: 1.25em;
    height: 1.25em; }

.annotation-preview-modal .panoview-annotation-item-view .panoview-annotation-item-image {
  width: 90%;
  margin-top: 0.75vw;
  margin-bottom: 0.75vw;
  margin-left: 5%; }
  .annotation-preview-modal .panoview-annotation-item-view .panoview-annotation-item-image img {
    width: 100%; }

.ant-modal-wrap {
  z-index: 1000; }

.ant-modal {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 520px;
  max-width: 520px; }

