@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background: #f4f8fa;
}

.content {
  height: calc(100vh - 80px);
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

@media (max-width: 1250px) {
  .hide-md {
    display: none;
  }
}

@media (max-width: 1000px) {
  .btn-responsive {
    width: 100%;
    margin: 0.5em;
  }
}

@media (min-width: 800px) {
  .float-right-md {
    float: right;
  }
}

.center-hz {
  align-items: center;
}

.center {
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.page-title {
  font-size: 2em;
  font-weight: 700;
}

.card-title {
  font-weight: 700;
  color: #111;
  font-size: 0.9em;
}

.secondary {
  color: #333;
  font-weight: 700;
  font-size: 0.75em;
}

.muted-text {
  color: #949494;
  font-weight: 700;
}

#root .ant-input {
  background: #f5f5f5;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: 0;
  box-shadow: none;
}

#root .ant-input:focus {
  background: #f5f5f5;
}

#root .ant-input-prefix {
  margin-right: 12px;
}

.card {
  width: 300px;
  height: 300px;
  background: #fff;
  box-shadow: rgba(24, 53, 88, 0.13) 0px 2px 2px 1px;
  margin: 0.5vw;
}
.container {
  padding: 0 50px;
}

.ant-modal {
  width: fit-content !important;
  min-width: 520px;
}

.extract-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}

.annotation-new-modal {
  .bottom {
    display: flex;
    justify-content: space-around;
  }
}

.annotation-preview-modal {
  .spinview-annotation-item-view {
    border-radius: 0.5vw;
    color: rgba(0, 0, 0, 0.85);
    padding: 0.5vw;
    cursor: pointer;

    .spinview-annotation-item-title {
      position: relative;
      .svg-inline--fa.fa-edit {
        position: absolute;
        right: 0;
        width: 1.25em;
        height: 1.25em;
      }
    }

    .spinview-annotation-item-image {
      width: 90%;
      margin-top: 0.75vw;
      margin-bottom: 0.75vw;
      margin-left: 5%;

      img {
        width: 100%;
      }
    }

    p {
      font-weight: 700;
      text-align: center;
      margin-bottom: 0.5vw;
    }

    &.active {
      border: 3px solid #10ff00;
    }
  }

  .panoview-annotation-item-view {
    @extend .spinview-annotation-item-view;

    .panoview-annotation-item-title {
      position: relative;
      .svg-inline--fa.fa-edit {
        position: absolute;
        right: 0;
        width: 1.25em;
        height: 1.25em;
      }
    }

    .panoview-annotation-item-image {
      width: 90%;
      margin-top: 0.75vw;
      margin-bottom: 0.75vw;
      margin-left: 5%;

      img {
        width: 100%;
      }
    }
  }
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal {
  width: fit-content !important;
  min-width: 520px;
  max-width: 520px;
}
