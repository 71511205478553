@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.nav {
  height: 5vh;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
}

.nav-right {
  margin-right: 75px;
}

.editor-logo {
  text-decoration: none;
  color: #1c1c21;
}

.editor-content {
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.sidebar {
  height: 100%;
  background: #fff;
  display: flex;
}

.sidebar-inner {
  width: 80px;
  border-right: 1px solid #eee;
  height: 100%;
}

.sidebar-outer {
  background: #eee;
  width: 0;
  border-top: 1px solid #eee;
  height: 100%;
  transition: 0.25s ease-in-out all;
  position: relative;
}

.sidebar-outer .branding-upload-image-btn {
  position: fixed;
  top: 93%;
  width: 8%;
}

.sidebar-outer .branding-cancel-upload-btn {
  position: fixed;
  top: 93%;
  width: 8%;
}

.sidebar-outer.active {
  border-left: solid rgb(187, 187, 187) 1px;
  width: 260px;
  overflow-y: scroll;
}

.sidebar-outer.active .filtering-sidebar,
.sidebar-outer.active .filtering-sidebar div,
.sidebar-outer.active .branding-sidebar,
.sidebar-outer.active .branding-sidebar div {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.sidebar-outer.active h3 {
  margin: 1em 0 0 0;
}

.sidebar-outer.active h4 {
  margin: 0.5em 0 0.2em 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul:first-child {
  border-top: 1px solid #eee;
}

.sidebar li {
  border-bottom: 1px solid #eee;
  padding: 1em 2em 1em;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 9vh;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar li:hover {
  background: #eee;
}

.sidebar li:hover .sidebar-tooltip {
  display: flex;
  flex-direction: column;
}

.sidebar li:hover .sidebar-tooltip.hide {
  display: none;
}

.sidebar-tooltip {
  position: absolute;
  right: -160px;
  width: 150px;
  background: #fff;
  z-index: 1;
  display: none;
  border-radius: 2px;
  box-shadow: rgba(24, 53, 88, 0.13) 0px 2px 2px 1px;
}

.sidebar-tooltip-body {
  padding: 1em;
}

.sidebar-tooltip::before {
  position: absolute;
  bottom: calc(50% - 5px);
  left: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid rgba(255, 255, 255, 1);
  content: '';
}

.sidebar-tooltip h5 {
  font-size: 1.2em;
  font-weight: 900;
  margin: 0 0 0.2em;
}

.sidebar-tooltip p {
  font-size: 0.9em;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.sidebar li .icon {
  margin-right: 12px;
  font-size: 18px;
  color: #555;
}

.sidebar .feature.active {
  background: #eee;
}

.editor-container {
  flex-grow: 1;
  /*background: #16161a;*/
  background: #1c1c21;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.editor-container footer button {
  margin-right: 1em;
  font-size: 0.75em;
}

.editor {
  display: inline-grid;
  height: 90%;
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.canvas-wrapper {
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

.editor-container footer {
  padding: 1em;
  background: #fff;
  height: 10%;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  color: black;
  font-size: 18px;
}

.photo-loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}

.editor .canvas-wrapper.loading {
  opacity: 0;
}

.editor .canvas-wrapper canvas {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
  max-height: 100%;
}

.editor .canvas-wrapper .cropper-container {
  z-index: 1;
}

.hidden {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}